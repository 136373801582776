import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

/**
 * Social Icons
 */
const SocialIcons = () => (
    <div className="SocialIcons">
        <ul className="list-unstyled text-center mb-0">
            <li className="list-unstyled-item">
                <a href="https://github.com/bulldogbarbells">
                    <FontAwesomeIcon icon={faGithub} />
                </a>
            </li>
        </ul>
    </div>
);

export default SocialIcons;
