import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Masthead from './components/Masthead';
import Overlay from './components/Overlay';
import SocialIcons from './components/SocialIcons';

function App() {
    return (
        <Provider store={store}>
            <Overlay />
            <Masthead />
            <SocialIcons />
        </Provider>
    );
}

export default App;
