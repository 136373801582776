import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export interface MastheadProps {}

const Masthead: React.FC<MastheadProps> = () => (
    <div className="Masthead">
        <div className="Masthead-background"></div>
        <Container className="h-100">
            <Row className="h-100">
                <Col xs="12" className="my-auto">
                    <div className="Masthead-content text-white py-5 py-md-0">
                        <h1 className="mb-3">Coming Soon!</h1>
                        <p className="mb-5">
                            We're working hard to finish the development of this site.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

export default Masthead;
