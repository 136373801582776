import {
    SIGN_UP_FOR_UPDATES,
    SIGN_UP_FOR_UPDATES_ERROR,
    SIGN_UP_FOR_UPDATES_SUCCESS
} from '../../config/actions';
import { combineReducers } from 'redux';

const INITIAL_STATE = {
    success: null
};

export default combineReducers({
    signUpForUpdates: (state = INITIAL_STATE, action) => {
        switch (action.type) {
            case SIGN_UP_FOR_UPDATES:
                return { ...state, success: null };

            case SIGN_UP_FOR_UPDATES_ERROR:
                return { ...state, success: false };

            case SIGN_UP_FOR_UPDATES_SUCCESS:
                return { ...state, success: true };

            default:
                return state;
        }
    }
});
